import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const bio = this.props.data.bio;
    const email = this.props.data.email;

    return (
      <section id="about">
        <FadeIn transitionDuration={1000}>
          <div className="row">
            <div className="nine columns main-col">
              <h2>About Me</h2>

              <p>{bio}</p>
              <div className="row">
                <div className="columns contact-details">
                  <h2>Contact</h2>
                  <p className="address">
                    <span>{email}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </FadeIn>
      </section>
    );
  }
}

export default About;
