import React, { Component } from "react";
import ModalImage from "react-modal-image";
import FadeIn from "react-fade-in";

let id = 0;
class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    const artwork = this.props.data.artwork.map(function (artwork) {
      let projectImage = "images/portfolio/" + artwork.image;
      let projectThumbnail = "images/portfolio/" + artwork.thumbnail;

      return (
        <div key={id++} className="columns portfolio-item">
          <div className="item-wrap">
            <ModalImage small={projectThumbnail} large={projectImage} />
            <div style={{ textAlign: "center" }}>{artwork.title}</div>
          </div>
        </div>
      );
    });

    return (
      <section id="portfolio">
        <FadeIn transitionDuration={1200}>
          <div className="row-portfolio">
            <div className="twelve columns collapsed">
              <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {artwork}
              </div>
            </div>
          </div>
        </FadeIn>
      </section>
    );
  }
}

export default Portfolio;
