import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import bg from "./assets/img/bg.png";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const comic = this.props.data.comic;
    const name = this.props.data.name;
    const description = this.props.data.description;

    return (
      <header
        id="home"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#portfolio">
                Works
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#pricing">
                Rates
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <FadeIn>
              <h1 className="responsive-headline">{name}</h1>
            </FadeIn>
            <FadeIn transitionDuration={1200}>
              <h3>{description}.</h3>
            </FadeIn>
            <hr />
            <FadeIn transitionDuration={2000}>
              <ul className="social">
                <a href={comic} className="button btn comic-btn">
                  <i className="fa fa-eye"></i>LILLETTE comic (18+)
                </a>
              </ul>
            </FadeIn>
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
