import React, { Component } from "react";
import useCollapse from "react-collapsed";

function Collapsible() {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  return (
    <div className="collapsible">
      <div
        className="header"
        {...getToggleProps()}
        style={{ cursor: "pointer" }}
      >
        {isExpanded ? "Exit Terms of Service ×" : "View Terms of Service ▾"}
      </div>
      <div {...getCollapseProps()}>
        <div className="content">
          <div
            style={{
              height: "600px",
              width: "100%",
              border: "1px solid #ccc",
              font: "16px/26px Georgia, Garamond, Serif",
              overflow: "auto",
            }}
          >
            <p>
              DISCLAIMER: By commissioning artwork from me and upon payment of
              the commission invoice, the commissioner agrees to being 18 years
              of age or older and agreeing to all terms of service as defined
              below.
            </p>

            <p>TERMS OF SERVICE:</p>

            <p>
              Commission slots are to be managed by a sole commissioner and are
              non-transferable. Payment is expected to be sent within 48 hours
              of receiving the invoice. Paypal only for USD payments. The
              commissioner can re-upload the image for any non-commercial
              purpose. Credit to my account is not required, but appreciated.
              Artwork cannot be used commercially or claimed as your own.
            </p>

            <p> SECTION I. IMAGE COPYRIGHT:</p>

            <p>
              I, the artist, retain full and exclusive rights to the original
              artwork. The client may not claim it as their own or use it for
              anything other than personal use. All commissioned artwork is for
              noncommercial purposes only. Redistribution of my artwork for use
              in printed merchandise or as promotion of goods, services or
              social media pages, or to mint or sell as NFTs (non-fungible
              tokens) is prohibited. If you have any questions about these
              terms, please ask before commissioning.
            </p>

            <p>
              Conditions for Reposting The client has my permission to publicly
              repost the artwork wherever they would like.
            </p>

            <p>
              Prohibited Actions Claiming the artwork as your own.
              Redistributing the artwork for paid promotion, including hosting
              to for-profit websites, merchandising, or cryptocurrency sales.
            </p>

            <p>
              If these terms are broken, you will be barred from commissioning
              me in the future.
            </p>

            <p>SECTION II. PAYMENT INFORMATION:</p>

            <p>
              I accept payment in USD. All USD payments will be taken via Paypal
              only. You will be given 48 hours to pay after a quote is given.
              Non-payment within 48 hours will result in the commission being
              canceled. It is the commissioner's responsibility to tell me when
              the payment has been sent. Work on the commission will not begin
              until full payment is received.
            </p>

            <p>
              Refund Policy If the commission is canceled (by me or the client)
              before work has started, the client will receive a full refund. If
              the client cancels the commission during progress, the amount
              refunded will be on a case-by-case basis and up to the
              artist&rsquo;s discretion. No refunds will be given for completed
              or nearly completed commissions. The artist reserves the right to
              cancel a commission at any time, for any reason, and without
              explanation. If I cancel the commission due to my inability to
              complete it in any state, a full refund will be given.
            </p>

            <p>SECTION III. CONTACT AND COMMUNICATION</p>

            <p>
              Discussion of commissions must be conducted via email
              (bennidova@gmail.com) or Twitter direct messages (username:
              bennidova).
            </p>

            <p>
              Visual Reference Guidelines The client must provide at least one
              clear and concise visual reference; please supplement the visual
              reference with any important information I may need to know to
              correctly draw the character.
            </p>

            <p>SECTION IV. WILL/WON'T DRAW</p>

            <p>
              Below is a general guideline for what I will or will not draw.
              Please note this list is not entirely comprehensive or guaranteed,
              discretion of what will be drawn is at the discretion of the
              artist.
            </p>

            <p>
              Allowed Content: Humans Anthro Furries Original Characters Fanart
              (with limitations) Eroticism (with limitations)
            </p>

            <p>
              Disallowed Content: Portraits/imagery of real people Characters
              intended to be or appear underage Fictional characters who are
              underage in more than 50% of the canon work in which they appear
              Extreme gore Extreme fetish Non-consensual acts Any hateful
              content, such as but not limited to racism, transphobia,
              homophobia, fascism/fascist imagery or paraphernalia.
            </p>

            <p>
              You must be 18 years of age or older to commission artwork. I am a
              United States citizen and abide by United States laws. Breaking
              this agreement will result in being barred from commissions and
              blocked on all social media, email, and all other means of
              contact. By commissioning artwork, you are agreeing that you are
              at or above the age of 18.
            </p>

            <p>SECTION V. WORKFLOW PROCESS</p>

            <p>
              I work best when given some artistic freedom to create an image
              based on the characters you provide. I am happy to match a scene
              or pose that you have in mind, but please allow and expect some
              variation. Estimated date to completion. If there are any delays
              in completion of a commission, I will personally notify the
              customer.
            </p>

            <p>
              Completion of most commissions are generally estimated to take no
              more than roughly 2 to 3 weeks. If for any reason I cannot
              complete a commission within 90 days of ordering, I will issue a
              full refund.
            </p>

            <p>
              Sketch Approval Stage. I will send the initial sketch of the
              commission to the commissioner via their preferred contact method
              (email or Twitter) and allow the commissioner to give feedback on
              changes before continuing work. Progress will be paused until the
              sketch is confirmed.
            </p>

            <p>
              Revisions and Edits. During the sketch approval stage, any major
              changes or revisions must be made at this stage; this includes any
              significant changes to a character&rsquo;s pose or body shape, or
              redrawing parts of the image. After the sketch has been approved,
              no major edits or changes will be made. If the commissioner
              requests additional edits after the approved sketch, a price for
              the edits may be discussed. If a major edit is requested, and upon
              completion the buyer chooses to use the original image, an
              additional fee may be invoked due to additional labor.
            </p>

            <p>
              If I, the artist, make any errors in the character design,
              accessories, or any other visual elements that were explicitly
              specified in the provided visual references or otherwise
              communicated in the final version of the artwork, I will make
              these edits at no additional cost. Errors in the finished
              commission that were not previously communicated or provided in
              the visual references will incur an additional cost.
            </p>

            <p>
              Once the commission is complete, I may ask permission to post the
              commission before posting. I will only post commissions to social
              media if the commissioner has given me permission to post.
            </p>

            <p>
              {" "}
              Thank you for your interest in my artwork. Please don&rsquo;t
              hesitate to ask me any questions though any of the following
              methods: Email: bennidova@gmail.com Twitter: bennidova
            </p>

            <p>
              {" "}
              *This TOS may be subject to change at any time without notice.
              Changes will not be applied retroactively.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
class Pricing extends Component {
  render() {
    if (!this.props.data) return null;

    const illustration = this.props.data.illustration.map(function (
      illustration
    ) {
      return (
        <div key={illustration.illustationType}>
          <h3>{illustration.illustationType}</h3>
          <p className="info">
            {illustration.illustrationPrices} <span>&bull;</span>
            <em className="date">{illustration.illustationNote}</em>
          </p>
          <p>{illustration.description}</p>
        </div>
      );
    });

    const misc = this.props.data.misc.map(function (misc) {
      return (
        <div key={misc.rateDesc}>
          <h3>{misc.rateDesc}</h3>
          <p className="info">
            {misc.rateStarting}
            <span>&bull;</span> <em className="date">{misc.miscNote}</em>
          </p>
          <p>{misc.description}</p>
        </div>
      );
    });

    return (
      <section id="pricing">
        <div className="row illustration">
          <h5 style={{ textAlign: "center" }}>
            <Collapsible />
          </h5>
        </div>
        <div className="row illustration">
          <div className="three columns header-col">
            <h1>
              <span>Illustrations</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">{illustration}</div>
            </div>
          </div>
        </div>
        {/* </Slide> */}

        {/* <Slide left duration={1300}> */}
        <div className="row misc">
          <div className="three columns header-col">
            <h1>
              <span>Animations/Comics</span>
            </h1>
          </div>

          <div className="nine columns main-col">{misc}</div>
        </div>
        {/* </Slide> */}
      </section>
    );
  }
}

export default Pricing;
