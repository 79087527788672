import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useCookies } from "react-cookie";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modalStyle: { backgroundColor: "black" },
  contentStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  button: {
    "&:hover": {
      backgroundColor: "#fff",
      color: "#c44c98",
    },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};
function Verification() {
  const [unverified, setUnverified] = React.useState(false);
  const [cookies, setCookie] = useCookies(["user"]);

  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setCookie("Verified", true, { path: "/" });
    setUnverified(false);
    setOpen(false);
  };
  const handleUnverified = () => {
    setCookie("Unverified", true, { path: "/" });
    setUnverified(true);
    console.log(unverified);
  };

  const classes = useStyles();

  return (
    <div>
      {!cookies.Verified && (
        <>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modalStyle}
          >
            <Box sx={style} className={classes.contentStyle}>
              {unverified && (
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Must be <b>18 years or older</b> to view content.
                </Typography>
              )}
              {!unverified && (
                <>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Are you 18 years of age or older?
                  </Typography>
                  <Button
                    sx={{ color: "#902f6d" }}
                    className={classes.button}
                    onClick={handleUnverified}
                  >
                    <b>No</b>
                  </Button>
                  <Button
                    sx={{ color: "#902f6d" }}
                    className={classes.button}
                    onClick={handleClose}
                  >
                    <b>Yes</b>
                  </Button>
                </>
              )}
            </Box>
          </Modal>
        </>
      )}
      {cookies.Unverified && (
        <>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modalStyle}
          >
            <Box sx={style} className={classes.contentStyle}>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Must be <b>18 years or older</b> to view content.
              </Typography>
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
}
export default Verification;
